
@font-face {
  font-family: "Blender pro";
  font-weight: normal;
  src: url('/public/fonts/BlenderPro-Book.woff2');
}




:root {
  --variable-collection-final-blue: rgba(0, 173, 239, 1);
  --variable-collection-final-grey: rgba(120, 116, 133, 1);
  --variable-collection-final-red: rgba(255, 0, 0, 1);
  --variable-collection-main-yello: rgba(255, 211, 0, 1);
  --variable-collection-white: rgba(255, 255, 255, 1);
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Blender Pro', sans-serif; /* Specify fallback fonts if Blender Pro isn't available */
}

a{
  color: #000;
  text-decoration: none;
}
.frame {
  background-color: var(--variable-collection-final-red);
}
.header-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--variable-collection-final-red);
  height: 76px;
}

.header-message .sign-up-and-to-get {
  color: transparent;
  font-family: "Blender Pro", Helvetica;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0;
}

.header-message .text-wrapper {
  color: var(--variable-collection-white);
}

.header-message .span {
  color: var(--variable-collection-main-yello);
}

.navbar {
  background-color: #ffd300;
  border: 0px none;
  height: 114px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0 50px !important;
}
.navbar .navbar-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 10px 8px;
}

.navbar .text-wrapper {
  color: var(--variable-collection-white);
  font-family: "Blender Pro", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.navbar .hmza-logo {
  height: 46px;
  object-fit: cover;
  width: 131px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navbar .div {
  align-items: flex-start;
  display: inline-flex;
  gap: 24px;
  justify-content: flex-end;
  padding: 0px 8px;
}
.menu-icon,
.white {
  color: var(--variable-collection-white);
}
.black {
  color: black;
}

.navbar .img {
  height: 24px;
  width: 24px;
}

.navbar2 {
  background-color: #ffffff;
  height: 107px;
  width: 1440px;
}

.navbar2 .navbar {
  align-items: flex-start;
  display: inline-flex;
  gap: 48px;
  left: 348px;
  padding: 24px;
  position: relative;
  top: 25px;
}

.navbar2 .text-wrapper {
  color: #000000;
  font-family:  "Blender Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.navbar2 .div {
  color: #000000;
  font-family:  "Blender Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.navigation-bar {
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;
  gap: 48px;
}
@media (max-width: 767px) {
  .navigation-bar {
    display: none;
  }
}

/* .hero {
  height: 90vh;
  flex-shrink: 0;
  background-color: gainsboro;
} */

.small-inmiddle-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--main-yello, #ffd300);
}
.elevate-your-strength {
  color: var(--Final-red, #f00);
  font-family:  "Blender Pro", Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 4.16px;
}

/* =========== Categories ====================== */

.Categories {
}
.heading2 {
  color: #000;
  text-align: center;
  font-family: "Blender Pro", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.categories-list {
  display: flex;
}
.categories-name {
  width: 234.141px;
  height: 234.141px;
  flex-shrink: 0;
}

.founderSection {
  width: 100%;
  height: 407px;
  background: var(--main-yello, #ffd300);
}

/* some specified classname */
.mt-5 {
  margin-top: 40px;
}
img {
  width: 100%;
  height: 100%;
}
.redButton {
  display: flex;
  width: 147px;
  height: 51px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 7px 16px;
  background-color: #ff0000;
  border-radius: 10px;
  box-shadow: 0px 4px 4px #00000040;
  border: none;
  color: var(--variable-collection-white);
  color: var(--white, #fff);
  font-family: "Blender Pro", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* ============================== */
.certificateSection {
  overflow: auto;
  display: flex;
  justify-content: center;
  gap: 50px;
}
.certificateCard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 350px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: #f9f4ec;
}
.certificateImage {
  width: 190px;
  height: 107px;
}

.athleteImage {
  width: 90%;
  height: 90%;
}

.reviewBox {
  border-radius: 30px;
  background: #f9f4ed;
  position: absolute;
  width: 80%;
  margin-top: -10px;
}
.reviewslider {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px;
}
.reviewslider > h5,.reviewslider > h6, .reviewslider > button {
  color: #000;
  text-align: center;
  font-family:  "Blender Pro";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
}
.reviewslider> p {
  color: #000;
  text-align: center;
  font-family:  "Blender Pro";
  font-size: 32px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.96px;
  padding: 10px;
}

.footerP > p{
  color: var(--white, #FFF);
font-family:  "Blender Pro";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.Homecontainer{
  
}